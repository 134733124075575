@media (max-width: $screen-sm) {
    // sm-view
    .about-company-short { 
        width: 110.35%;
        margin-left: -5.175%;
        margin-bottom: 30px;
        overflow: hidden;
        .about-company-short-holder { 
            border-radius: 0;
            >.text {
                .text-holder { 
                    padding: 30px 4.69% 28px;
                    .text-contains {
                        p {
                            margin: 0 0 13px;
                        }

                        .action {
                            margin: 20px 0 0;
                        }
                    }
                }
            }

            .image { 
                height: 101.5vw;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
