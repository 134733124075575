@media (max-width: $screen-sm) {
    // sm-view
    .company-points-block { 
        .company-points-block-holder { 
            padding: 31px 0 16px;
            .points { 
                width: 100%;
                margin-left: 0;
                display: inline-block;
                .point {
                    padding: 0;
                    width: 100%;
                    margin: 0 0 9px;
                    .point-holder { 
                        font-size: 14px;
                        line-height: 160%;
                        &:after {
                            display: none;
                        }

                        .icon {

                        }

                        .text {
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
