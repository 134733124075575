@media (max-width: $screen-lg) {
    // lg-md view
    .brands-sliders-block { 
        margin: 0 0 36px;
        .brands-sliders-block-holder { 
            .brannds-sliders-container { 
                height: 311px;
                .big-left { 
                    width: 58%;
                    .brands-slider { 
                        padding: 29px 21.2%;
                        .nav { 
                            ul.slick-dots {
                                bottom: 18px;
                            }
                        }
                    }
                }

                .small-right {
                    width: 42%;
                    .brand-accent-slider { 
                        .brand-accent-slider-holder { 
                            .brand-accent-slide { 

                                .nav { 
                                    ul.slick-dots {
                                        bottom: 18px;
                                    }
                                }

                                .brand-accent-slide-holder { 
                                    a {
                                        padding: 69px 0 24px;
                                        .image { 
                                            margin: 0 0 21px;
                                        }

                                        .text { 
                                            align-items: flex-start;
                                            .text-holder {
                                                width: 65%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
