.catalog-root-block.vertical-overlap {
    display: block;
    transform: translateY(-72px);
    margin: 0 0 -14px;
}
.catalog-root-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 40px;
	.catalog-root-block-holder { 
        display: inline-block;
        width: 100%;
        .catalog-categories { 
            display: inline-block;
            width: 100%;
            border-radius: 6px;
            overflow: hidden;
            .category.gray {
                .category-holder { 
                    background: $gray-light;
                }
            }
            .category { 
                width: 50%;
                float: left;
                box-sizing: border-box;
                .category-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 280px;
                    background: white;
                    position: relative;
                    .category-image { 
                        display: inline-block;
                        width: 210px;
                        height: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 1;
                        img { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .category-contains { 
                        display: inline-block;
                        width: 79%;
                        height: 79%;
                        position: relative;
                        z-index: 2;
                        box-sizing: border-box;
                        padding: 30px 40px 0px;
                        overflow: hidden;
                        .name { 
                            margin: 0 0 24px;
                            width: 110%;
                            a { 

                                span { 
                                    color: $brand-primary;
                                }

                            }
                        }

                        .sub-categories { 

                            ul.col-2 {
                                li {
                                    width: 50%;
                                }
                            }
                            ul.col-3 {
                                li {
                                    width: 33.333%;
                                }
                            }
                            ul { 
                                display: inline-block;
                                width: calc(100% + 14px);
                                margin-left: -7px;
                                li { 
                                    float: left;
                                    width: 100%;
                                    box-sizing: border-box;
                                    padding: 0 7px;
                                    margin: 0 0 22px;
                                    a { 

                                    }
                                }
                            }
                        }
                    }

                    .show-all { 
                        position: absolute;
                        left: 40px;
                        bottom: 22px;
                        z-index: 3;
                        a { 
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 160%;
                            color: $brand-primary;
                            text-decoration: underline;
                            text-decoration-color: currentColor;
                            &:hover {
                                color: $brand-primary-accent;
                                text-decoration-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/root";
@import "../../media/mobile/includes/catalog/root";
