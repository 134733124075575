@media (max-width: $screen-sm) {
    // sm-view
    .news-slider-block {
        overflow: hidden;
        margin: 0 0 37px;
        .news-slider-block-holder { 

            .slick-slider {
                .slick-list {
                    overflow: hidden;
                    .slick-track {
                        .slick-slide {
                            >div {
                                
                            }
                        }
                    }
                }
            }

            .news-slider { 
                box-sizing: border-box;
                padding: 0 0 15px 0;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
