@media (max-width: $screen-sm) {
    // sm-view
    .brands-list-block { 
        margin: 0 0 28px;
        .brands-list-block-holder { 
            border-radius: 0;
            width: 110.35%;
            margin-left: -5.175%;
            padding: 15px 5.175% 18px;
            .brands-list, .brands-list-slider { 
                width: 100%;
                margin-left: 0;
                ul.manufacturers-list { 
                    padding: 0;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
