.news-slider-block { 
    margin: 0 0 80px;
	.news-slider-block-holder { 

        .news-slider { 

        }
    }
}

@import "../../media/tablet/includes/common/news_slider";
@import "../../media/mobile/includes/common/news_slider";
