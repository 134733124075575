.how-to-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 58px;
	.how-to-block-holder { 
        display: inline-block;
        width: 100%;
        .how-to-list { 
            display: inline-block;
            width: calc(100% + 6px);
            margin-left: -3px;
            .how-to-item { 
                float: left;
                width: 20%;
                box-sizing: border-box;
                padding: 0 3px;
                .how-to-item-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 245px;
                    background: $gray-light;
                    box-sizing: border-box;
                    padding: 31px 22px 0;
                    .number  { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 17px;
                        .value {
                            float: left;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 30px;
                            color: $brand-primary;
                            height: 30px;
                            margin-left: 17px;
                            position: relative;
                            z-index: 2;
                            .inner-value {
                                display: inline-block;
                                min-width: 50px;
                                height: 100%;
                                text-align: center;
                                background: #f6f7f8;
                                position: relative;
                                z-index: 3;
                                border-radius: 20px;
                            }
                            &:after {
                                content: "";
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                z-index: 1;
                                background: #edf0f2;
                                position: absolute;
                                left: -17px;
                                top: 0;
                                border-radius: 20px;
                            }
                        }
                    }

                    .text { 
                        display: inline-block;
                        width: 100%;
                        .name { 
                            font-style: normal;
                            font-weight: 800;
                            font-size: 18px;
                            line-height: 140%;
                            margin: 0 0 9px;
                        }

                        .desc { 
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 160%;
                            color: $gray-dark;
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/how_to";
@import "../../media/mobile/includes/common/how_to";
