.brands-list-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 40px;
	.brands-list-block-holder { 
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 40px 40px 7px;
        border-radius: 6px;
        background: white;
        overflow: hidden;
        .heading { 
            display: inline-block;
            width: 100%;
        }

        .brands-list { 
            display: inline-block;
            width: 100%;
            ul.manufacturers-list { 
                column-count: 4;
                column-gap: 40px;
                li {
                    margin: 0 0 24px;
                }
            }
        }
    }
}

ul.manufacturers-list { 
    display: inline-block;
    width: 100%;
    li { 
        display: inline-block;
        width: 100%;
        margin: 0 0 8px;
        a { 
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
            .image { 
                width: 98px;
                height: 38px;
                border: 1px solid $gray-light;
                display: flex;
                align-items: center;
                justify-content: center;
                img { 
                    width: 70px;
                    height: 36px;
                    object-fit: contain;
                }
            }

            .name { 
               margin-left: 16px; 
            }
        }
    }
}

@import "../../media/tablet/includes/common/brands_list";
@import "../../media/mobile/includes/common/brands_list";
