.catalog-item.small-view .catalog-item-holder .image {
  height: 197px;
}
.catalog-item.small-view .catalog-item-holder .desc {
  padding: 12px 16px 0;
  height: 97px;
}
.catalog-item.small-view .catalog-item-holder .desc .desc-holder .vendor-code {
  margin: 0 0 11px;
}
.catalog-item.small-view .catalog-item-holder .desc .desc-holder .name {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.catalog-item.small-view .catalog-item-holder .order {
  height: 57px;
  padding: 0 13px 0;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder {
  width: 100%;
  margin: 0;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder .price {
  width: calc(100% - 58px);
  padding: 0;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder .action {
  width: auto;
  padding: 0;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder .action .btn {
  padding: 10px 10px;
  width: 58px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder .action .btn .icon {
  display: block !important;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder .action .btn .text {
  display: none !important;
}

.catalog-item {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder {
  display: inline-block;
  width: 100%;
  background: white;
  border-radius: 6px;
  overflow: hidden;
}
.catalog-item .catalog-item-holder .image {
  display: inline-block;
  width: 100%;
  height: 258px;
}
.catalog-item .catalog-item-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.catalog-item .catalog-item-holder .image .image-holder .shields {
  position: absolute;
  left: 12px;
  top: 16px;
  z-index: 2;
  max-width: 50%;
}
.catalog-item .catalog-item-holder .image .image-holder .shields .shield {
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 160%;
  padding: 3px 6px;
  background: #DD4841;
  color: white;
  float: left;
  margin: 0 3px;
  border-radius: 4px;
}
.catalog-item .catalog-item-holder .image .image-holder .mfg {
  position: absolute;
  right: 17px;
  top: 10px;
  z-index: 2;
}
.catalog-item .catalog-item-holder .image .image-holder .mfg img {
  max-width: 70px;
  max-height: 36px;
  object-fit: contain;
}
.catalog-item .catalog-item-holder .image .image-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.catalog-item .catalog-item-holder .image .image-holder a:hover {
  opacity: 0.8;
}
.catalog-item .catalog-item-holder .image .image-holder a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.catalog-item .catalog-item-holder .desc {
  display: inline-block;
  width: 100%;
  height: 115px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 14px 25px 0;
}
.catalog-item .catalog-item-holder .desc .desc-holder {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .desc .desc-holder .vendor-code {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #888A8B;
  margin: 0 0 9px;
}
.catalog-item .catalog-item-holder .desc .desc-holder .name {
  display: inline-block;
  width: 100%;
  height: 64px;
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
}
.catalog-item .catalog-item-holder .order {
  display: inline-block;
  width: 100%;
  height: 64px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 22px 0;
}
.catalog-item .catalog-item-holder .order .order-holder {
  display: flex;
  align-items: center;
  width: calc(100% + 9px);
  margin-left: -4.5px;
}
.catalog-item .catalog-item-holder .order .order-holder .price {
  float: left;
  width: 46.5%;
  box-sizing: border-box;
  padding: 0 9px;
}
.catalog-item .catalog-item-holder .order .order-holder .price .value {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 140%;
}
.catalog-item .catalog-item-holder .order .order-holder .action {
  float: right;
  width: 53.5%;
  box-sizing: border-box;
  padding: 0 9px;
}
.catalog-item .catalog-item-holder .order .order-holder .action .btn {
  width: 100%;
  text-align: center;
  padding: 6px 10px;
}

@media (max-width: 1279px) {
  .catalog-item.small-view .catalog-item-holder .image {
    height: 132px;
  }
  .catalog-item.small-view .catalog-item-holder .image .image-holder .shields {
    left: 5px;
    top: 7px;
  }
  .catalog-item.small-view .catalog-item-holder .image .image-holder .mfg {
    right: auto;
    top: auto;
    left: 12px;
    bottom: -24px;
  }
  .catalog-item.small-view .catalog-item-holder .image .image-holder .mfg img {
    max-width: 50px;
    max-height: 25px;
  }
  .catalog-item.small-view .catalog-item-holder .desc {
    padding: 31px 12px 0;
    height: 111px;
  }
  .catalog-item.small-view .catalog-item-holder .desc .desc-holder .vendor-code {
    font-size: 11px;
    margin: 0 0 8px;
  }
  .catalog-item.small-view .catalog-item-holder .order {
    padding: 0 9px 0;
    height: 41px;
  }
  .catalog-item.small-view .catalog-item-holder .order .order-holder .price {
    width: calc(100% - 34px);
  }
  .catalog-item.small-view .catalog-item-holder .order .order-holder .price .value {
    font-size: 16px;
  }
  .catalog-item.small-view .catalog-item-holder .order .order-holder .action .btn {
    width: 34px;
    height: 30px;
    padding: 1px 1px;
  }
  .catalog-item.small-view .catalog-item-holder .order .order-holder .action .btn .icon svg {
    width: 18px;
    height: 18px;
  }
  .catalog-item .catalog-item-holder .image {
    height: 26vw;
  }
  .catalog-item .catalog-item-holder .desc {
    padding: 10px 16px 0;
    height: 94px;
  }
  .catalog-item .catalog-item-holder .desc .desc-holder .name {
    font-size: 12px;
    line-height: 140%;
    height: 50px;
  }
  .catalog-item .catalog-item-holder .order {
    height: 57px;
    padding: 0 12px 0;
  }
  .catalog-item .catalog-item-holder .order .order-holder {
    width: 100%;
    margin: 0;
  }
  .catalog-item .catalog-item-holder .order .order-holder .price {
    width: calc(100% - 58px);
    padding: 0;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action {
    width: 58px;
    padding: 0;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action .btn {
    padding: 10px 18px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action .btn .icon {
    width: 22px;
    height: 22px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action .btn .icon svg {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .catalog-item.small-view .catalog-item-holder .desc {
    padding: 13px 11px 0;
    height: 92px;
  }
  .catalog-item.small-view .catalog-item-holder .order {
    height: 42px;
  }
  .catalog-item .catalog-item-holder .image {
    height: 40vw;
    margin: 0 0 20px;
  }
  .catalog-item .catalog-item-holder .image .image-holder .shields {
    left: 3px;
    top: 4px;
  }
  .catalog-item .catalog-item-holder .image .image-holder .mfg {
    right: auto;
    top: auto;
    bottom: -25px;
    left: 10px;
  }
  .catalog-item .catalog-item-holder .image .image-holder .mfg img {
    max-width: 50px;
    max-height: 26px;
  }
  .catalog-item .catalog-item-holder .desc {
    padding: 14px 9px 0;
  }
  .catalog-item .catalog-item-holder .desc .desc-holder .vendor-code {
    font-size: 11px;
  }
  .catalog-item .catalog-item-holder .order {
    padding: 0 9px 0;
    height: 41px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .price {
    width: calc(100% - 34px);
  }
  .catalog-item .catalog-item-holder .order .order-holder .price .value {
    font-size: 16px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action {
    width: 34px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action .btn {
    padding: 4px 7px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action .btn .icon {
    width: 18px;
    height: 18px;
  }
}
.items-sider-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 57px;
}
.items-sider-block.small-gap {
  margin: 0 0 37px;
}
@media (max-width: 1279px) {
  .items-sider-block {
    overflow: hidden;
    margin: 0 0 25px;
  }
  .items-sider-block .items-slider-block-holder .items-slider {
    padding: 0 0 19px;
  }
  .items-sider-block .items-slider-block-holder .slick-slider .slick-list {
    overflow: visible;
  }
}
.catalog-root-block.vertical-overlap {
  display: block;
  transform: translateY(-72px);
  margin: 0 0 -14px;
}

.catalog-root-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 40px;
}
.catalog-root-block .catalog-root-block-holder {
  display: inline-block;
  width: 100%;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories {
  display: inline-block;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category.gray .category-holder {
  background: #E6E9EC;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category {
  width: 50%;
  float: left;
  box-sizing: border-box;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder {
  display: inline-block;
  width: 100%;
  height: 280px;
  background: white;
  position: relative;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-image {
  display: inline-block;
  width: 210px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains {
  display: inline-block;
  width: 79%;
  height: 79%;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: 30px 40px 0px;
  overflow: hidden;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .name {
  margin: 0 0 24px;
  width: 110%;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .name a span {
  color: #12A356;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .sub-categories ul.col-2 li {
  width: 50%;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .sub-categories ul.col-3 li {
  width: 33.333%;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .sub-categories ul {
  display: inline-block;
  width: calc(100% + 14px);
  margin-left: -7px;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .sub-categories ul li {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 7px;
  margin: 0 0 22px;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .show-all {
  position: absolute;
  left: 40px;
  bottom: 22px;
  z-index: 3;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .show-all a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #12A356;
  text-decoration: underline;
  text-decoration-color: currentColor;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .show-all a:hover {
  color: #008248;
  text-decoration-color: transparent;
}

@media (max-width: 1279px) {
  .catalog-root-block.vertical-overlap {
    transform: translateY(-47px);
    margin: 0 0 -18px;
  }
  .catalog-root-block {
    margin: 0 0 29px;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder {
    height: 125px;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-image {
    width: auto;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 2px 100px 0 40px;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .name {
    margin: 0;
    font-size: 16px;
    line-height: 160%;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .sub-categories {
    display: none;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .show-all {
    display: none;
  }
}
@media (max-width: 767px) {
  .catalog-root-block.vertical-overlap {
    transform: none;
    margin: 0 0 -3px;
  }
  .catalog-root-block .catalog-root-block-holder {
    width: 110.35%;
    margin-left: -5.175%;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category.gray .category-holder {
    background: white;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category:nth-of-type(2n + 1) .category-holder {
    background: #E6E9EC !important;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category {
    width: 100%;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder {
    height: 82px;
    overflow: hidden;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-image {
    top: -9%;
    height: 116%;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains {
    padding: 0px 85px 0 9.5%;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .name {
    line-height: 160%;
  }
}
.brands-list-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 40px;
}
.brands-list-block .brands-list-block-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 40px 7px;
  border-radius: 6px;
  background: white;
  overflow: hidden;
}
.brands-list-block .brands-list-block-holder .heading {
  display: inline-block;
  width: 100%;
}
.brands-list-block .brands-list-block-holder .brands-list {
  display: inline-block;
  width: 100%;
}
.brands-list-block .brands-list-block-holder .brands-list ul.manufacturers-list {
  column-count: 4;
  column-gap: 40px;
}
.brands-list-block .brands-list-block-holder .brands-list ul.manufacturers-list li {
  margin: 0 0 24px;
}

ul.manufacturers-list {
  display: inline-block;
  width: 100%;
}
ul.manufacturers-list li {
  display: inline-block;
  width: 100%;
  margin: 0 0 8px;
}
ul.manufacturers-list li a {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
}
ul.manufacturers-list li a .image {
  width: 98px;
  height: 38px;
  border: 1px solid #E6E9EC;
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.manufacturers-list li a .image img {
  width: 70px;
  height: 36px;
  object-fit: contain;
}
ul.manufacturers-list li a .name {
  margin-left: 16px;
}

@media (max-width: 1279px) {
  .brands-list-block {
    margin: 0 0 17px;
  }
  .brands-list-block .brands-list-block-holder {
    padding: 27px 40px 29px;
  }
  .brands-list-block .brands-list-block-holder .heading {
    margin: 0 0 18px;
  }
  .brands-list-block .brands-list-block-holder .brands-list, .brands-list-block .brands-list-block-holder .brands-list-slider {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .brands-list-block .brands-list-block-holder .brands-list .brands-slider, .brands-list-block .brands-list-block-holder .brands-list-slider .brands-slider {
    padding: 0 0 10px;
  }
  .brands-list-block .brands-list-block-holder .brands-list ul.manufacturers-list, .brands-list-block .brands-list-block-holder .brands-list-slider ul.manufacturers-list {
    column-count: 1;
    column-gap: 0px;
    box-sizing: border-box;
    padding: 0 15px;
  }
  .brands-list-block .brands-list-block-holder .brands-list ul.manufacturers-list li, .brands-list-block .brands-list-block-holder .brands-list-slider ul.manufacturers-list li {
    margin: 0 0 24px;
  }
  .brands-list-block .brands-list-block-holder .brands-list ul.manufacturers-list li:last-of-type, .brands-list-block .brands-list-block-holder .brands-list-slider ul.manufacturers-list li:last-of-type {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .brands-list-block {
    margin: 0 0 28px;
  }
  .brands-list-block .brands-list-block-holder {
    border-radius: 0;
    width: 110.35%;
    margin-left: -5.175%;
    padding: 15px 5.175% 18px;
  }
  .brands-list-block .brands-list-block-holder .brands-list, .brands-list-block .brands-list-block-holder .brands-list-slider {
    width: 100%;
    margin-left: 0;
  }
  .brands-list-block .brands-list-block-holder .brands-list ul.manufacturers-list, .brands-list-block .brands-list-block-holder .brands-list-slider ul.manufacturers-list {
    padding: 0;
  }
}
.company-points-block {
  display: inline-block;
  width: 100%;
  background: #314248;
  color: white;
}
.company-points-block .company-points-block-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 54px 2.5%;
}
.company-points-block .company-points-block-holder .points {
  display: flex;
  width: calc(100% + 120px);
  margin-left: -60px;
}
.company-points-block .company-points-block-holder .points .point {
  float: left;
  width: 33.333%;
  box-sizing: border-box;
  padding: 0 60px;
}
.company-points-block .company-points-block-holder .points .point:nth-of-type(3n) .point-holder:after {
  display: none;
}
.company-points-block .company-points-block-holder .points .point .point-holder {
  display: flex;
  align-items: center;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  position: relative;
}
.company-points-block .company-points-block-holder .points .point .point-holder:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  opacity: 0.1;
  border-left: 1px solid white;
  position: absolute;
  right: -60px;
  top: 0;
}
.company-points-block .company-points-block-holder .points .point .point-holder .icon {
  width: 40px;
  height: 40px;
  float: left;
}
.company-points-block .company-points-block-holder .points .point .point-holder .icon svg {
  width: 100%;
  height: 100%;
}
.company-points-block .company-points-block-holder .points .point .point-holder .text {
  width: calc(100% - 40px);
  float: left;
  box-sizing: border-box;
  padding-left: 30px;
}

@media (max-width: 1279px) {
  .company-points-block .company-points-block-holder {
    padding: 35px 0 36px;
  }
  .company-points-block .company-points-block-holder .points {
    width: calc(100% + 35px);
    margin-left: -17.5px;
  }
  .company-points-block .company-points-block-holder .points .point {
    padding: 0 17.5px;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder {
    font-size: 14px;
    line-height: 160%;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder:after {
    display: none;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder .text {
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .company-points-block .company-points-block-holder {
    padding: 31px 0 16px;
  }
  .company-points-block .company-points-block-holder .points {
    width: 100%;
    margin-left: 0;
    display: inline-block;
  }
  .company-points-block .company-points-block-holder .points .point {
    padding: 0;
    width: 100%;
    margin: 0 0 9px;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder {
    font-size: 14px;
    line-height: 160%;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder:after {
    display: none;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder .text {
    padding-left: 20px;
  }
}
.common-heading-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.common-heading-block .common-heading-block-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.common-heading-block .common-heading-block-holder .text {
  float: left;
  width: 90%;
  margin: 0;
}
.common-heading-block .common-heading-block-holder .text .text-contains {
  display: inline;
}
.common-heading-block .common-heading-block-holder .text .icon {
  display: inline;
  color: #12A356;
}
.common-heading-block .common-heading-block-holder .text .icon svg {
  width: 24px;
  height: 24px;
  margin: 9px 0 0 10px;
}
.common-heading-block .common-heading-block-holder .action {
  width: 10%;
  float: right;
  display: flex;
  justify-content: flex-end;
  margin-right: -7px;
}
.common-heading-block .common-heading-block-holder .action .prev, .common-heading-block .common-heading-block-holder .action .next {
  float: left;
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  transform: none;
  margin: 0 7px;
}
@media (max-width: 1279px) {
  .common-heading-block {
    margin: 0 0 17px;
  }
  .common-heading-block .common-heading-block-holder .text .icon svg {
    margin: 6px 0 0 10px;
  }
}
@media (max-width: 767px) {
  .common-heading-block {
    margin: 0 0 20px;
  }
  .common-heading-block .common-heading-block-holder .text {
    width: 95%;
  }
  .common-heading-block .common-heading-block-holder .text .icon svg {
    margin: 7px 0 0 5px;
  }
  .common-heading-block .common-heading-block-holder .action {
    display: none;
  }
}
.how-to-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 58px;
}
.how-to-block .how-to-block-holder {
  display: inline-block;
  width: 100%;
}
.how-to-block .how-to-block-holder .how-to-list {
  display: inline-block;
  width: calc(100% + 6px);
  margin-left: -3px;
}
.how-to-block .how-to-block-holder .how-to-list .how-to-item {
  float: left;
  width: 20%;
  box-sizing: border-box;
  padding: 0 3px;
}
.how-to-block .how-to-block-holder .how-to-list .how-to-item .how-to-item-holder {
  display: inline-block;
  width: 100%;
  height: 245px;
  background: #E6E9EC;
  box-sizing: border-box;
  padding: 31px 22px 0;
}
.how-to-block .how-to-block-holder .how-to-list .how-to-item .how-to-item-holder .number {
  display: inline-block;
  width: 100%;
  margin: 0 0 17px;
}
.how-to-block .how-to-block-holder .how-to-list .how-to-item .how-to-item-holder .number .value {
  float: left;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #12A356;
  height: 30px;
  margin-left: 17px;
  position: relative;
  z-index: 2;
}
.how-to-block .how-to-block-holder .how-to-list .how-to-item .how-to-item-holder .number .value .inner-value {
  display: inline-block;
  min-width: 50px;
  height: 100%;
  text-align: center;
  background: #f6f7f8;
  position: relative;
  z-index: 3;
  border-radius: 20px;
}
.how-to-block .how-to-block-holder .how-to-list .how-to-item .how-to-item-holder .number .value:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #edf0f2;
  position: absolute;
  left: -17px;
  top: 0;
  border-radius: 20px;
}
.how-to-block .how-to-block-holder .how-to-list .how-to-item .how-to-item-holder .text {
  display: inline-block;
  width: 100%;
}
.how-to-block .how-to-block-holder .how-to-list .how-to-item .how-to-item-holder .text .name {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 140%;
  margin: 0 0 9px;
}
.how-to-block .how-to-block-holder .how-to-list .how-to-item .how-to-item-holder .text .desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #4D5153;
}

@media (max-width: 1279px) {
  .how-to-block {
    margin: 0 0 20px;
  }
  .how-to-block .how-to-block-holder .how-to-list .how-to-item {
    width: 33.333%;
    margin: 0 0 6px;
  }
}
@media (max-width: 767px) {
  .how-to-block {
    margin: 0 0 22px;
  }
  .how-to-block .how-to-block-holder .how-to-list {
    width: 100%;
    margin-left: 0;
  }
  .how-to-block .how-to-block-holder .how-to-list .how-to-item {
    width: 100%;
    padding: 0;
  }
  .how-to-block .how-to-block-holder .how-to-list .how-to-item .how-to-item-holder {
    padding: 21px 22px 0;
    height: 200px;
  }
}
.news-item {
  display: inline-block;
  width: 100%;
}
.news-item .news-item-holder {
  display: inline-block;
  width: 100%;
  background: white;
  border-radius: 6px;
  overflow: hidden;
}
.news-item .news-item-holder .image {
  display: inline-block;
  width: 100%;
  height: 220px;
}
.news-item .news-item-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.news-item .news-item-holder .image .image-holder .date {
  position: absolute;
  z-index: 2;
  left: 11px;
  top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: white;
  background: #12A356;
  padding: 4px 10px;
}
.news-item .news-item-holder .image .image-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.news-item .news-item-holder .image .image-holder a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.news-item .news-item-holder .image .image-holder a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.news-item .news-item-holder .desc {
  display: inline-block;
  width: 100%;
  height: 182px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 24px 30px 32px;
  margin: 0 0 21px;
}
.news-item .news-item-holder .desc .name {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 140%;
  margin: 0 0 12px;
}
.news-item .news-item-holder .desc .text {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #4D5153;
}

@media (max-width: 1279px) {
  .news-item .news-item-holder .image {
    height: 21.5vw;
  }
  .news-item .news-item-holder .desc {
    height: auto;
    padding: 20px 30px 20px;
    margin: 0;
  }
  .news-item .news-item-holder .desc .name {
    height: 92px;
    overflow: hidden;
    font-size: 14px;
    line-height: 154%;
    margin: 0;
  }
  .news-item .news-item-holder .desc .text {
    display: none;
  }
}
@media (max-width: 767px) {
  .news-item .news-item-holder .image {
    height: 51vw;
  }
  .news-item .news-item-holder .image .image-holder .date {
    top: 10px;
  }
}
.news-slider-block {
  margin: 0 0 80px;
}
@media (max-width: 1279px) {
  .news-slider-block {
    overflow: hidden;
    margin: 0 0 37px;
  }
  .news-slider-block .news-slider-block-holder .slick-slider {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .news-slider-block .news-slider-block-holder .slick-slider .slick-list {
    overflow: visible;
  }
  .news-slider-block .news-slider-block-holder .slick-slider .slick-list .slick-track .slick-slide {
    padding: 0 10px;
  }
  .news-slider-block .news-slider-block-holder .news-slider {
    box-sizing: border-box;
    padding: 0 13.2% 15px 0;
  }
}
@media (max-width: 767px) {
  .news-slider-block {
    overflow: hidden;
    margin: 0 0 37px;
  }
  .news-slider-block .news-slider-block-holder .slick-slider .slick-list {
    overflow: hidden;
  }
  .news-slider-block .news-slider-block-holder .news-slider {
    box-sizing: border-box;
    padding: 0 0 15px 0;
  }
}
.about-company-short {
  display: inline-block;
  width: 100%;
  margin: 0 0 61px;
}
.about-company-short .about-company-short-holder {
  display: flex;
  width: 100%;
}
.about-company-short .about-company-short-holder > .text {
  width: 57.7%;
  height: auto;
}
.about-company-short .about-company-short-holder > .text .text-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #008248;
  color: white;
  position: relative;
  box-sizing: border-box;
  padding: 40px;
}
.about-company-short .about-company-short-holder > .text .text-holder .image-bkg {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 221px;
  height: 312px;
  opacity: 0.5;
}
.about-company-short .about-company-short-holder > .text .text-holder .image-bkg svg {
  width: 100%;
  height: 100%;
}
.about-company-short .about-company-short-holder > .text .text-holder .text-contains {
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 2;
}
.about-company-short .about-company-short-holder > .text .text-holder .text-contains h2, .about-company-short .about-company-short-holder > .text .text-holder .text-contains .h2 {
  margin: 0 0 26px;
}
.about-company-short .about-company-short-holder > .text .text-holder .text-contains .accent strong {
  font-weight: 800;
}
.about-company-short .about-company-short-holder > .text .text-holder .text-contains p {
  margin: 0 0 26px;
}
.about-company-short .about-company-short-holder > .text .text-holder .text-contains .action {
  display: inline-block;
  width: 100%;
  margin: 7px 0 0;
}
.about-company-short .about-company-short-holder > .text .text-holder .text-contains .action a {
  display: inline-block;
  color: white;
  font-weight: 600;
  line-height: 18px;
}
.about-company-short .about-company-short-holder > .text .text-holder .text-contains .action a:hover {
  color: #314248;
}
.about-company-short .about-company-short-holder > .text .text-holder .text-contains .action a .text {
  float: left;
}
.about-company-short .about-company-short-holder > .text .text-holder .text-contains .action a .icon {
  float: left;
  margin-left: 7px;
}
.about-company-short .about-company-short-holder .image {
  width: 42.3%;
  height: auto;
}
.about-company-short .about-company-short-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.about-company-short .about-company-short-holder .image .image-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1279px) {
  .about-company-short {
    margin: 0 0 29px;
  }
  .about-company-short .about-company-short-holder {
    flex-wrap: wrap;
    border-radius: 6px;
    overflow: hidden;
  }
  .about-company-short .about-company-short-holder > .text {
    width: 100%;
  }
  .about-company-short .about-company-short-holder > .text .text-holder {
    padding: 40px 40px 31px;
  }
  .about-company-short .about-company-short-holder > .text .text-holder .text-contains h2, .about-company-short .about-company-short-holder > .text .text-holder .text-contains .h2 {
    margin: 0 0 14px;
  }
  .about-company-short .about-company-short-holder > .text .text-holder .text-contains p {
    margin: 0 0 17px;
  }
  .about-company-short .about-company-short-holder > .text .text-holder .text-contains .action {
    margin: 14px 0 0;
  }
  .about-company-short .about-company-short-holder .image {
    width: 100%;
    height: 54.5vw;
  }
}
@media (max-width: 767px) {
  .about-company-short {
    width: 110.35%;
    margin-left: -5.175%;
    margin-bottom: 30px;
    overflow: hidden;
  }
  .about-company-short .about-company-short-holder {
    border-radius: 0;
  }
  .about-company-short .about-company-short-holder > .text .text-holder {
    padding: 30px 4.69% 28px;
  }
  .about-company-short .about-company-short-holder > .text .text-holder .text-contains p {
    margin: 0 0 13px;
  }
  .about-company-short .about-company-short-holder > .text .text-holder .text-contains .action {
    margin: 20px 0 0;
  }
  .about-company-short .about-company-short-holder .image {
    height: 101.5vw;
  }
}
.brands-sliders-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 59px;
}
.brands-sliders-block .brands-sliders-block-holder {
  display: inline-block;
  width: 100%;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container {
  display: inline-block;
  width: calc(100% + 20px);
  margin-left: -10px;
  height: 323px;
  overflow: hidden;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left {
  width: 75%;
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 10px;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider {
  background: white;
  box-sizing: border-box;
  padding: 43px 7.2%;
  height: 100%;
  border-radius: 6px;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider .prev, .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider .next {
  left: 16px;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider .next {
  left: auto;
  right: 16px;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider .nav ul.slick-dots {
  bottom: 16px;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider .brands-slider-holder {
  width: calc(100% + 46px);
  margin-left: -23px;
  height: 100%;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider .brands-slider-holder .brands-slide {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 23px;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider .brands-slider-holder .brands-slide .brands-slide-holder {
  display: inline-block;
  width: 100%;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right {
  width: 25%;
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 10px;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider {
  border-radius: 6px;
  background: #E6E9EC;
  height: 100%;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .nav ul.slick-dots {
  bottom: 16px;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 160%;
  padding: 72px 0 24px;
  box-sizing: border-box;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder a .image {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0 0 0;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder a .image img {
  width: 180px;
  height: 39px;
  object-fit: contain;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder a .text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 165px;
  text-align: center;
}
.brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder a .text .text-holder {
  display: inline-block;
  width: 60%;
}

@media (max-width: 1279px) {
  .brands-sliders-block {
    margin: 0 0 36px;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container {
    height: 311px;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left {
    width: 58%;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider {
    padding: 29px 21.2%;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider .nav ul.slick-dots {
    bottom: 18px;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right {
    width: 42%;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .nav ul.slick-dots {
    bottom: 18px;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder a {
    padding: 69px 0 24px;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder a .image {
    margin: 0 0 21px;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder a .text {
    align-items: flex-start;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder a .text .text-holder {
    width: 65%;
  }
}
@media (max-width: 767px) {
  .brands-sliders-block {
    margin: 0 0 25px;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container {
    height: auto;
    width: 100%;
    margin-left: 0;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left {
    width: 100%;
    padding: 0;
    height: 313px;
    margin: 0 0 20px;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .big-left .brands-slider {
    padding: 29px 10.2%;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right {
    width: 100%;
    padding: 0;
    height: 227px;
  }
  .brands-sliders-block .brands-sliders-block-holder .brannds-sliders-container .small-right .brand-accent-slider .brand-accent-slider-holder .brand-accent-slide .brand-accent-slide-holder a {
    padding: 37px 0 24px;
  }
}
.main-slider {
  display: inline-block;
  width: 100%;
  height: 620px;
  margin: 0 0 -3px;
}
.main-slider .main-slider-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.main-slider .main-slider-holder .controlls {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  pointer-events: none;
  touch-action: none;
}
.main-slider .main-slider-holder .controlls .wrapper-inside {
  height: 100%;
}
.main-slider .main-slider-holder .controlls .controlls-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.main-slider .main-slider-holder .controlls .controlls-holder .prev, .main-slider .main-slider-holder .controlls .controlls-holder .next {
  pointer-events: all;
  touch-action: auto;
}
.main-slider .main-slider-holder .main-sldier-container {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-slider .main-slider-holder .main-sldier-container .slide {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-slider .main-slider-holder .main-sldier-container .slide .slide-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-image {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content.right .slide-content-holder {
  width: 50%;
  float: right;
  padding: 91px 71px 0 18px;
  box-sizing: border-box;
}
.main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content.left .slide-content-holder {
  width: 50%;
  float: left;
  padding: 91px 18px 0 71px;
  box-sizing: border-box;
}
.main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
}
.main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content .wrapper-inside {
  height: 100%;
}
.main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content .slide-content-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content .slide-content-holder .heading {
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  margin: 0 0 14px;
}
.main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content .slide-content-holder .text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
}

@media (max-width: 1279px) {
  .main-slider {
    height: 49.5vw;
  }
  .main-slider .main-slider-holder .controlls .controlls-holder .nav ul.slick-dots {
    bottom: 59px;
  }
  .main-slider .main-slider-holder .controlls .controlls-holder .nav ul.slick-dots li button {
    background-color: white;
  }
  .main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content.right .slide-content-holder {
    padding: 70px 5px 0 30px;
  }
  .main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content.left .slide-content-holder {
    padding: 70px 30px 0 5px;
  }
  .main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content .slide-content-holder .heading {
    font-size: 24px;
    line-height: 140%;
  }
  .main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content .slide-content-holder .text {
    font-size: 14px;
    line-height: 160%;
  }
}
@media (max-width: 767px) {
  .main-slider {
    height: 93.5vw;
  }
  .main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content.right .slide-content-holder {
    width: 100%;
    padding: 21px 0 0 0;
  }
  .main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content.left .slide-content-holder {
    width: 100%;
    padding: 21px 0 0 0;
  }
  .main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content .slide-content-holder {
    padding: 21px 0 0 0;
  }
  .main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content .slide-content-holder .heading {
    font-size: 21px;
    line-height: 140%;
    margin: 0;
    text-align: center;
  }
  .main-slider .main-slider-holder .main-sldier-container .slide .slide-holder .slide-content .slide-content-holder .text {
    display: none;
  }
}