@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-root-block.vertical-overlap {
        transform: translateY(-47px);
        margin: 0 0 -18px;
    }
    .catalog-root-block { 
        margin: 0 0 29px;
        .catalog-root-block-holder { 
            .catalog-categories { 
                .category { 
                    .category-holder { 
                        height: 125px;
                        .category-image { 
                            width: auto;
                        }

                        .category-contains { 
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            padding: 2px 100px 0 40px;
                            .name { 
                                margin: 0;
                                font-size: 16px;
                                line-height: 160%;
                            }

                            .sub-categories { 
                                display: none;
                            }
                        }

                        .show-all { 
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
