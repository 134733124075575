@media (max-width: $screen-lg) {
    // lg-md view
    .brands-list-block { 
        margin: 0 0 17px;
        .brands-list-block-holder { 
            padding: 27px 40px 29px;
            .heading { 
                margin: 0 0 18px;
            }

            .brands-list, .brands-list-slider { 
                width: calc(100% + 30px);
                margin-left: -15px;

                .brands-slider {
                    padding: 0 0 10px;
                }

                ul.manufacturers-list { 
                    column-count: 1;
                    column-gap: 0px;
                    box-sizing: border-box;
                    padding: 0 15px;
                    li {
                        margin: 0 0 24px;
                        &:last-of-type {
                            margin: 0;
                        }
                    }
                }
                
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
