@media (max-width: $screen-lg) {
    // lg-md view
    .news-slider-block {
        overflow: hidden;
        margin: 0 0 37px;
        .news-slider-block-holder { 

            .slick-slider {
                width: calc(100% + 20px);
                margin-left: -10px;
                .slick-list {
                    overflow: visible;
                    .slick-track {
                        .slick-slide {
                            padding: 0 10px;
                            >div {
                                
                            }
                        }
                    }
                }
            }

            .news-slider { 
                box-sizing: border-box;
                padding: 0 13.2% 15px 0;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
