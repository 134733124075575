.about-company-short { 
    display: inline-block;
    width: 100%;
    margin: 0 0 61px;
	.about-company-short-holder { 
        display: flex;
        width: 100%;
        >.text { 
            width: 57.7%;
            height: auto;
            .text-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                background: $brand-primary-accent;
                color: white;
                position: relative;
                box-sizing: border-box;
                padding: 40px;
                .image-bkg { 
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    width: 221px;
                    height: 312px;
                    opacity: 0.5;
                    svg { 
                        width: 100%;
                        height: 100%;
                    }
                }

                .text-contains {
                    display: inline-block;
                    width: 100%;
                    position: relative;
                    z-index: 2;
                    h2, .h2 { 
                        margin: 0 0 26px;
                    }
    
                    .accent { 
    
                        strong { 
                            font-weight: 800;
                        }
                    }
    
                    br { 
    
                    }
    
                    p { 
                        margin: 0 0 26px;
                    }
    
                    .action { 
                        display: inline-block;
                        width: 100%;
                        margin: 7px 0 0;
                        a { 
                            display: inline-block;
                            color: white;
                            font-weight: 600;
                            line-height: 18px;
                            &:hover {
                                color: $brand-secondary;
                            }
                            .text { 
                                float: left;
                            }
    
                            .icon { 
                                float: left;
                                margin-left: 7px;
                                svg { 
                                }
                            }
                        }
                    }
                }

            }
        }

        .image { 
            width: 42.3%;
            height: auto;
            .image-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/about";
@import "../../media/mobile/includes/index/about";
