.news-item { 
    display: inline-block;
    width: 100%;
    .news-item-holder { 
        display: inline-block;
        width: 100%;
        background: white;
        border-radius: 6px;
        overflow: hidden;
        .image { 
            display: inline-block;
            width: 100%;
            height: 220px;
            .image-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                position: relative;
                .date { 
                    position: absolute;
                    z-index: 2;
                    left: 11px;
                    top: 12px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 140%;
                    color: white;
                    background: $brand-primary;
                    padding: 4px 10px;
                }

                a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                    z-index: 1;
                    &:hover {
                        img {
                            transform: scale(1.1);
                            transition: all 300ms;
                        }
                    }
                    img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 300ms;
                    }
                }
            }
        }

        .desc { 
            display: inline-block;
            width: 100%;
            height: 182px;
            overflow: hidden;
            box-sizing: border-box;
            padding: 24px 30px 32px;
            margin: 0 0 21px;
            .name { 
                display: inline-block;
                width: 100%;
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 140%;
                margin: 0 0 12px;
                a { 

                }
            }

            .text { 
                display: inline-block;
                width: 100%;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 160%;
                color: $gray-dark;
            }
        }
    }
}

@import "../../media/tablet/includes/common/news_item";
@import "../../media/mobile/includes/common/news_item";
