@media (max-width: $screen-sm) {
    // sm-view
    .brands-sliders-block { 
        margin: 0 0 25px;
        .brands-sliders-block-holder { 
            .brannds-sliders-container { 
                height: auto;
                width: 100%;
                margin-left: 0;
                .big-left { 
                    width: 100%;
                    padding: 0;
                    height: 313px;
                    margin: 0 0 20px;
                    .brands-slider { 
                        padding: 29px 10.2%;
                    }
                }

                .small-right {
                    width: 100%;
                    padding: 0;
                    height: 227px;
                    .brand-accent-slider { 
                        .brand-accent-slider-holder { 
                            .brand-accent-slide { 
                                .brand-accent-slide-holder { 
                                    a {
                                        padding: 37px 0 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
