@media (max-width: $screen-sm) {
    // sm-view
    .main-slider { 
        height: 93.5vw;
        .main-slider-holder { 
            .controlls { 

            }

            .main-sldier-container { 
                .slide { 
                    .slide-holder { 
                        .slide-content.right {
                            .slide-content-holder { 
                                width: 100%;
                                padding: 21px 0 0 0;
                            }
                        }
                        .slide-content.left {
                            .slide-content-holder { 
                                width: 100%;
                                padding: 21px 0 0 0;
                            }
                        }


                        .slide-content { 
                            .slide-content-holder { 
                                padding: 21px 0 0 0;
                                .heading { 
                                    font-size: 21px;
                                    line-height: 140%;
                                    margin: 0;
                                    text-align: center;
                                }

                                .text { 
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
