@media (max-width: $screen-sm) {
    // sm-view
    .catalog-item.small-view {
        .catalog-item-holder { 
            .desc { 
                padding: 13px 11px 0;
                height: 92px;
            }

            .order {
                height: 42px;
            }
        }
    }
    .catalog-item { 
        .catalog-item-holder { 
            .image { 
                height: 40vw;
                margin: 0 0 20px;
                .image-holder {
                    .shields { 
                        left: 3px;
                        top: 4px;
                    }

                    .mfg { 
                        right: auto;
                        top: auto;
                        bottom: -25px;
                        left: 10px;
                        img {
                            max-width: 50px;
                            max-height: 26px;
                        }
                    }
                }
            }

            .desc { 
                padding: 14px 9px 0;
                .desc-holder { 
                    .vendor-code {
                        font-size: 11px;
                    }

                    .name { 

                    }
                }
            }

            .order {
                padding: 0 9px 0;
                height: 41px;
                .order-holder { 
                    .price {
                        width: calc(100% - 34px);
                        .value { 
                            font-size: 16px;
                        }
                    }

                    .action {
                        width: 34px;
                        .btn {
                            padding: 4px 7px;
                            .icon {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
