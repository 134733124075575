@media (max-width: $screen-lg) {
    // lg-md view
    .main-slider { 
        height: 49.5vw;
        .main-slider-holder { 
            .controlls { 
                .controlls-holder { 
                    .nav { 
                        ul.slick-dots {
                            bottom: 59px;
                            li {
                                button {
                                    background-color: white;
                                }
                            }
                        }
                    }
                }
            }

            .main-sldier-container { 
                .slide { 
                    .slide-holder { 

                        .slide-content.right {
                            .slide-content-holder { 
                                padding: 70px 5px 0 30px;
                            }
                        }
                        .slide-content.left {
                            .slide-content-holder { 
                                padding: 70px 30px 0 5px;
                            }
                        }

                        .slide-content { 
                            .slide-content-holder { 
                                .heading { 
                                    font-size: 24px;
                                    line-height: 140%;
                                }

                                .text { 
                                    font-size: 14px;
                                    line-height: 160%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
