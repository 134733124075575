@media (max-width: $screen-sm) {
    // sm-view
    .catalog-root-block.vertical-overlap {
        transform: none;
        margin: 0 0 -3px;
    }
    .catalog-root-block { 
        .catalog-root-block-holder { 
            width: 110.35%;
            margin-left: -5.175%;
            .catalog-categories { 
                .category.gray {
                    .category-holder { 
                        background: white;
                    }
                }
                .category:nth-of-type(2n + 1) {
                    .category-holder { 
                        background:  $gray-light !important;
                    }
                }
                .category { 
                    width: 100%;
                    .category-holder { 
                        height: 82px;
                        overflow: hidden;
                        .category-image { 
                            top: -9%;
                            height: 116%;
                        }

                        .category-contains { 
                            padding: 0px 85px 0 9.5%;
                            .name { 
                                line-height: 160%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
