.brands-sliders-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 59px;
	.brands-sliders-block-holder { 
        display: inline-block;
        width: 100%;
        .brannds-sliders-container { 
            display: inline-block;
            width: calc(100% + 20px);
            margin-left: -10px;
            height: 323px;
            overflow: hidden;
            .big-left { 
                width: 75%;
                height: 100%;
                float: left;
                box-sizing: border-box;
                padding: 0 10px;
                .brands-slider { 
                    background: white;
                    box-sizing: border-box;
                    padding: 43px 7.2%;   
                    height: 100%;      
                    border-radius: 6px;       
                    .prev, .next { 
                        left: 16px;
                        svg { 

                            path { 

                            }
                        }
                    }

                    .next {
                        left: auto;
                        right: 16px;
                    }

                    .nav { 
                        ul.slick-dots {
                            bottom: 16px;
                        }
                    }

                    .brands-slider-holder { 
                        width: calc(100% + 46px);
                        margin-left: -23px;
                        height: 100%;
                        .brands-slide { 
                            display: inline-block;
                            box-sizing: border-box;
                            padding: 0 23px;
                            .brands-slide-holder { 
                                display: inline-block;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .small-right { 
                width: 25%;
                height: 100%;
                float: left;
                box-sizing: border-box;
                padding: 0 10px;
                .brand-accent-slider { 
                    border-radius: 6px;
                    background: $gray-light;
                    height: 100%;      
                    .nav { 
                        ul.slick-dots {
                            bottom: 16px;
                        }
                    }

                    .brand-accent-slider-holder { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        .brand-accent-slide { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            .brand-accent-slide-holder { 
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                a { 
                                    display: inline-block;
                                    width: 100%;
                                    height: 100%;
                                    font-style: normal;
                                    font-weight: 800;
                                    font-size: 16px;
                                    line-height: 160%;
                                    padding: 72px 0 24px;
                                    box-sizing: border-box;
                                    .image { 
                                        display: inline-block;
                                        width: 100%;
                                        text-align: center;
                                        margin: 0 0 0;
                                        img { 
                                            width: 180px;
                                            height: 39px;
                                            object-fit: contain;
                                        }
                                    }

                                    .text { 
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 100%;
                                        height: 165px;
                                        text-align: center;
                                        .text-holder {
                                            display: inline-block;
                                            width: 60%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/brands_slider";
@import "../../media/mobile/includes/index/brands_slider";
