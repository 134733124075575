@media (max-width: $screen-lg) {
    // lg-md view
    .how-to-block { 
        margin: 0 0 20px;
        .how-to-block-holder { 
            .how-to-list { 
                .how-to-item { 
                    width: 33.333%;
                    margin: 0 0 6px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
