@media (max-width: $screen-sm) {
    // sm-view
    .news-item { 
        .news-item-holder { 
            .image { 
                height: 51vw;
                .image-holder {
                    .date {
                        top: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
