@media (max-width: $screen-sm) {
    // sm-view
    .how-to-block { 
        margin: 0 0 22px;
        .how-to-block-holder { 
            .how-to-list { 
                width: 100%;
                margin-left: 0;
                .how-to-item {
                    width: 100%;
                    padding: 0;
                    .how-to-item-holder {
                        padding: 21px 22px 0;
                        height: 200px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
