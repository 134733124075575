@media (max-width: $screen-lg) {
    // lg-md view
    .common-heading-block { 
        margin: 0 0 17px;
        .common-heading-block-holder { 
            .text { 
                .icon {
                    svg {
                        margin: 6px 0 0 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
