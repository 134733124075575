.items-sider-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 57px;
    &.small-gap {
        margin: 0 0 37px;
    }
	.items-slider-block-holder { 

        .items-slider { 

        }
    }
}

@import "../../media/tablet/includes/catalog/items_slider";
@import "../../media/mobile/includes/catalog/items_slider";
