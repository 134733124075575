@media (max-width: $screen-lg) {
    // lg-md view
    .company-points-block { 
        .company-points-block-holder { 
            padding: 35px 0 36px;
            .points { 
                width: calc(100% + 35px);
                margin-left: -17.5px;
                .point {
                    padding: 0 17.5px;
                    .point-holder { 
                        font-size: 14px;
                        line-height: 160%;
                        &:after {
                            display: none;
                        }

                        .icon {

                        }

                        .text {
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
