@media (max-width: $screen-lg) {
    // lg-md view
    .items-sider-block { 
        overflow: hidden;
        margin: 0 0 25px;
        .items-slider-block-holder { 

            .items-slider { 
                padding: 0 0 19px;
            }

            .slick-slider {
                .slick-list {
                    overflow: visible;
                    .slick-track {
                        .slick-slide {
                            >div {
                                
                            }
                        }
                    }
                }
            }

        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
