@media (max-width: $screen-lg) {
    // lg-md view
    .about-company-short { 
        margin: 0 0 29px;
        .about-company-short-holder { 
            flex-wrap: wrap;
            border-radius: 6px;
            overflow: hidden;
            >.text {
                width: 100%;
                .text-holder { 
                    padding: 40px 40px 31px;
                    .text-contains {
                        h2, .h2 {
                            margin: 0 0 14px;
                        }

                        p {
                            margin: 0 0 17px;
                        }

                        .action {
                            margin: 14px 0 0;
                        }
                    }
                }
            }

            .image { 
                width: 100%;
                height: 54.5vw;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
