.catalog-item.small-view {
    .catalog-item-holder { 
        .image { 
            height: 197px;
        }

        .desc { 
            padding: 12px 16px 0;
            height: 97px;
            .desc-holder { 
                .vendor-code {
                    margin: 0 0 11px;
                }

                .name { 
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }
        }

        .order { 
            height: 57px;
            padding: 0 13px 0;
            .order-holder { 
                width: 100%;
                margin: 0;
                .price { 
                    width: calc(100% - 58px);
                    padding: 0;
                }

                .action {
                    width: auto;
                    padding: 0;
                    .btn {
                        padding: 10px 10px;
                        width: 58px;
                        height: 42px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .icon {
                            display: block !important;
                        }

                        .text {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}
.catalog-item { 
    display: inline-block;
    width: 100%;
	.catalog-item-holder { 
        display: inline-block;
        width: 100%;
        background: white;
        border-radius: 6px;
        overflow: hidden;
		.image { 
            display: inline-block;
            width: 100%;
            height: 258px;
			.image-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                position: relative;
				.shields { 
                    position: absolute;
                    left: 12px;
                    top: 16px;
                    z-index: 2;
                    max-width: 50%;
					.shield { 
                        font-style: normal;
                        font-weight: 800;
                        font-size: 10px;
                        line-height: 160%;
                        padding: 3px 6px;
                        background: $brand-danger;
                        color: white;
                        float: left;
                        margin: 0 3px;
                        border-radius: 4px;
					}
				}

				.mfg { 
                    position: absolute;
                    right: 17px;
                    top: 10px;
                    z-index: 2;
					img { 
                        max-width: 70px;
                        max-height: 36px;
                        object-fit: contain;
					}
				}

				a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                    z-index: 1;
                    &:hover {
                        opacity: 0.8;
                    }
					img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
					}
				}
			}
		}

		.desc { 
            display: inline-block;
            width: 100%;
            height: 115px;
            overflow: hidden;
            box-sizing: border-box;
            padding: 14px 25px 0;
			.desc-holder { 
                display: inline-block;
                width: 100%;
				.vendor-code { 
                    display: inline-block;
                    width: 100%;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 140%;
                    color: $gray;
                    margin: 0 0 9px;
				}

				.name { 
                    display: inline-block;
                    width: 100%;
                    height: 64px;
                    overflow: hidden;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 160%;
					a { 

					}
				}
			}
		}

		.order { 
            display: inline-block;
            width: 100%;
            height: 64px;
            overflow: hidden;
            box-sizing: border-box;
            padding: 0 22px 0;
			.order-holder { 
                display: flex;
                align-items: center;
                width: calc(100% + 9px);
                margin-left: -4.5px;
				.price { 
                    float: left;
                    width: 46.5%;
                    box-sizing: border-box;
                    padding: 0 9px;
					.value { 
                        font-style: normal;
                        font-weight: 800;
                        font-size: 18px;
                        line-height: 140%;
					}
				}

				.action { 
                    float: right;
                    width: 53.5%;
                    box-sizing: border-box;
                    padding: 0 9px;
					.btn { 
                        width: 100%;
                        text-align: center;
                        padding: 6px 10px;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/item";
@import "../../media/mobile/includes/catalog/item";
