@media (max-width: $screen-lg) {
    // lg-md view
    .news-item { 
        .news-item-holder { 
            .image { 
                height: 21.5vw;
            }

            .desc { 
                height: auto;
                padding: 20px 30px 20px;
                margin: 0;
                .name {
                    height: 92px;
                    overflow: hidden;
                    font-size: 14px;
                    line-height: 154%;
                    margin: 0;
                }

                .text {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
