@media (max-width: $screen-sm) {
    // sm-view
    .common-heading-block { 
        margin: 0 0 20px;
        .common-heading-block-holder { 
            .text { 
                width: 95%;
                .icon {
                    svg {
                        margin: 7px 0 0 5px;
                    }
                }
            }
            .action {
                display: none;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
