.company-points-block { 
    display: inline-block;
    width: 100%;
    background: $brand-secondary;
    color: white;
	.company-points-block-holder { 
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 54px 2.5%;
        .points { 
            display: flex;
            width: calc(100% + 120px);
            margin-left: -60px;
            .point { 
                float: left;
                width: 33.333%;
                box-sizing: border-box;
                padding: 0 60px;
                &:nth-of-type(3n) {
                    .point-holder { 
                        &:after {
                            display: none;
                        }
                    }
                }
                .point-holder { 
                    display: flex;
                    align-items: center;
                    width: 100%;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 160%;
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        width: 0;
                        height: 100%;
                        opacity: 0.1;
                        border-left: 1px solid white;
                        position: absolute;
                        right: -60px;
                        top: 0;
                    }
                    .icon { 
                        width: 40px;
                        height: 40px;
                        float: left;
                        svg { 
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .text { 
                        width: calc(100% - 40px);
                        float: left;
                        box-sizing: border-box;
                        padding-left: 30px;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/company_points";
@import "../../media/mobile/includes/common/company_points";
