.main-slider { 
    display: inline-block;
    width: 100%;
    height: 620px;
    margin: 0 0 -3px;
	.main-slider-holder { 
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
		.controlls { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            pointer-events: none;
            touch-action: none;
			.wrapper-inside { 
                height: 100%;
			}

            .controlls-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                position: relative;
                .prev, .next { 
                    pointer-events: all;
                    touch-action: auto;
                    svg { 
                    }
                }

                .next { 

                }

                .nav { 

                }
            }
		}

		.main-sldier-container { 
            display: inline-block;
            width: 100%;
            height: 100%;
			.slide { 
                display: inline-block;
                width: 100%;
                height: 100%;
				.slide-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    position: relative;
					.slide-image { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 2;
						img { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
						}
					}

                    .slide-content.right {
                        .slide-content-holder { 
                            width: 50%;
                            float: right;
                            padding: 91px 71px 0 18px;
                            box-sizing: border-box;
                        }
                    }
                    .slide-content.left {
                        .slide-content-holder { 
                            width: 50%;
                            float: left;
                            padding: 91px 18px 0 71px;
                            box-sizing: border-box;
                        }
                    }

					.slide-content { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        z-index: 3;
						.wrapper-inside { 
                            height: 100%;
						}

                        .slide-content-holder { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            .heading { 
                                font-style: normal;
                                font-weight: 800;
                                font-size: 40px;
                                line-height: 55px; 
                                margin: 0 0 14px;                               
                            }

                            .text { 
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 160%;
                            }
                        }
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/main_slider";
@import "../../media/mobile/includes/index/main_slider";
