.common-heading-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 30px;
	.common-heading-block-holder { 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
		.text { 
            float: left;
            width: 90%;
            margin: 0;
			.text-contains { 
                display: inline;
			}

			.icon { 
                display: inline;
                color: $brand-primary;
				svg { 
                    width: 24px;
                    height: 24px;
                    margin: 9px 0 0 10px;
				}
			}
		}

		.action { 
            width: 10%;
            float: right;
            display: flex;
            justify-content: flex-end;
            margin-right: -7px;
			.prev, .next { 
                float: left;
                position: relative;
                left: auto;
                right: auto;
                top: auto;
                transform: none;
                margin: 0 7px;
				svg { 

				}
			}

			.next { 

				svg { 

				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/heading_block";
@import "../../media/mobile/includes/common/heading_block";
