@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-item.small-view {
        .catalog-item-holder { 
            .image { 
                height: 132px;
                .image-holder { 
                    .shields { 
                        left: 5px;
                        top: 7px;
                    }

                    .mfg { 
                        right: auto;
                        top: auto;
                        left: 12px;
                        bottom: -24px;
                        img {
                            max-width: 50px;
                            max-height: 25px;
                        }
                    }
                }
            }

            .desc { 
                padding: 31px 12px 0;
                height: 111px;
                .desc-holder {
                    .vendor-code { 
                        font-size: 11px;
                        margin: 0 0 8px;
                    }
                }
            }

            .order { 
                padding: 0 9px 0;
                height: 41px;
                .order-holder { 
                    .price { 
                        width: calc(100% - 34px);
                        .value {
                            font-size: 16px;
                        }
                    }

                    .action {
                        .btn {
                            width: 34px;
                            height: 30px;
                            padding: 1px 1px;
                            .icon {
                                svg {
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .catalog-item { 
        .catalog-item-holder { 
            .image { 
                height: 26vw;
            }

            .desc { 
                padding: 10px 16px 0;
                height: 94px;
                .desc-holder { 
                    .vendor-code { 

                    }

                    .name { 
                        font-size: 12px;
                        line-height: 140%;
                        height: 50px;
                    }
                }
            }

            .order { 
                height: 57px;
                padding: 0 12px 0;
                .order-holder {
                    width: 100%;
                    margin: 0;
                    .price {
                        width: calc(100% - 58px);
                        padding: 0;
                    }

                    .action { 
                        width: 58px;
                        padding: 0;
                        .btn {
                            padding: 10px 18px;
                            .icon {
                                width: 22px;
                                height: 22px;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
